import React, { useState, useRef, useContext, useEffect } from "react"
import Styles from "../styles/team_info_update.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { ToastContext } from '../context/ToastContext';
import GeneralButton from "../components/atoms/GeneralButton";
import InputInfoItem from "../components/InputInfoItem";
import SelectBoxWithInputSearch from "../components/SelectBoxWithInputSearch";
import PasswordChangeTemplate from "../templates/PasswordChangeTemplate";
import SportsCreateTemplate from "../templates/SportsCreateTemplate";
import PasswordResetTemplate from "../templates/PasswordResetTemplate";
import { SelectButtonType } from "../types/models/SelectButton";
import client from "../apis/client";
import { PlusIcon } from "../icons/PlusIcon";
import { ToggleButton } from "../components/ToggleButton";
import { SportsGetType__ } from "../types/models/Sports";

const VisibleModal = {
    CREATE_SPORTS: "createSports",
    CHANGE_PASSWORD: "changePassword",
    RESET_PASSWORD: "resetPassword",
} as const
type VisibleModalType = typeof VisibleModal[ keyof typeof VisibleModal ]

export interface Root {
    infoList: InfoType[]
    teamSportsList: TeamSportsType[]
    sportsList: SportsType[]
    imagePath: string
    key: string
}

export type InfoType = {
    name: string
    label: string
    value: string
}

export type TeamSportsType = {
    label: string
}

export type SportsType = {
    label: string
    name: number
    isSelected: boolean
}

const isFile = ( fileOrString: ( string | File ) ): fileOrString is File => !( typeof fileOrString === "string" )

const getInitialState = ( state: any ) => {
    if ( !state ) return {
        initialInfoList: [
            { name: "name", label: "チーム名", value: "" },
            { name: "idname", label: "チームID", value: "" },
            { name: "email", label: "メールアドレス", value: "" },
        ],
        initialSportsList: [],
        initialImagePath: undefined,
        initialOpenProfile: false,
        validInitialState: false
    }
    const stateInfoList: InfoType[] = state.infoList
    const stateSportsList: SportsType[] = state.sportsList
    const stateImagePath: string = state.imagePath
    return {
        initialInfoList: stateInfoList,
        initialSportsList: stateSportsList.map( sports => ( {
            label: sports.label,
            value: sports.name,
            isSelected: sports.isSelected
        } ) ),
        initialImagePath: stateImagePath,
        initialOpenProfile: state.openProfile,
        validInitialState: true
    }
}

const TeamInfoUpdatePage = ( {
    location
}: any ) => {

    const { initialInfoList, initialSportsList, initialImagePath, initialOpenProfile, validInitialState } = getInitialState( location.state )
    const [ infoList, setInfoList ] = useState( initialInfoList )
    const [ sportsList, setSportsList ] = useState<SelectButtonType<number>[]>( initialSportsList )
    const [ imagePath, setImagePath ] = useState<File | string | undefined>( initialImagePath )
    const [ openProfile, setOpenProfile ] = useState<boolean>( initialOpenProfile );
    const [ uploading, setUploading ] = useState( false )
    const [ visibleModal, setVisibleModal ] = useState<VisibleModalType | null>( null )
    const initialEmail = initialInfoList.filter( info => info.name === "email" ).map( ( info ) => info.value )[ 0 ]
    const { addToast } = useContext( ToastContext );

    const imageInputRef = useRef<HTMLInputElement>( null )

    const headerOption = {
        headerTitle: "チーム情報編集",
        leftIcon: <span onClick={ () => window.history.back() }>キャンセル</span>,
    }

    useEffect( () => {
        !validInitialState && getTeamData()
    }, [ validInitialState ] )


    const saveInfo = async () => {
        if ( uploading ) return

        const data = new FormData()
        infoList.forEach( info => {
            data.append( info.name, info.value )
        } );
        sportsList.forEach( sports => {
            sports.isSelected && data.append( 'sport', sports.value as any )
        } )
        data.append( "open_profile", openProfile as any )
        // if ( imagePath !== initialImagePath && imagePath ) {
        if ( imagePath && isFile( imagePath ) ) {
            const fileName = 99 < imagePath.name.length ?
                imagePath.name.slice( imagePath.name.length - 99 ) :
                imagePath.name
            data.append( "profile_image", imagePath, fileName )
        }
        // }
        setUploading( true )
        await client.patch( `/api/community/me/`, data )
            .then( () => {
                addToast( { text: "更新しました", type: "success" } )
                setUploading( false )
                window.history.back()
            } )
            .catch( error => {
                const data = error.response.data
                const imageError = data.profile_image
                imageError && imageError.forEach( ( e: string ) => alert( e ) )
                console.warn( error.response )
                const newInfoList = infoList.map( info => data[ info.name ] ?
                    { ...info, errorMessage: data[ info.name ][ 0 ] } :
                    info
                )
                setInfoList( newInfoList.slice() )
                setUploading( false )
            } )
    }


    const getTeamData = async () => {
        try {
            const { data } = await client.get( `/api/community/me/`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
            setInfoList( infoList.map( info => ( {
                ...info, value: data[ info.name ]
            } ) ).slice() )
            const sports_list: SportsGetType__[] = data.sports_list
            setSportsList( sports_list.map( sports => ( {
                label: sports.label,
                value: sports.name,
                isSelected: sports.isSelected
            } ) ) )
            // setSportsList( data.sports_list )
            setImagePath( data.profile_image )
            setOpenProfile( data.open_profile )
        }
        catch ( error ) {
            console.error( error )
        }
    }


    const onChangeSelectButton = ( boxName: string, buttonList: SelectButtonType<number>[] ) => {
        setSportsList( buttonList )
    }

    const onChangeInput = ( name: string, value: string ) => {
        const newInfoList = infoList.map( info => ( {
            ...info,
            value: info.name !== name ?
                info.value :
                value
        } ) )
        setInfoList( newInfoList.slice() )
    }

    const onChangeInputImage = () => {
        if ( !imageInputRef.current ) return
        const newImageFile = imageInputRef.current.files ? imageInputRef.current.files[ 0 ] : ""
        newImageFile && setImagePath( newImageFile )
    }

    const renderModal = () => {

        if ( !visibleModal ) return

        const updateSportsList = ( name: string, pk: number ) => {
            const newSportsList = sportsList.concat( {
                value: pk,
                label: name,
                isSelected: false
            } )
            setSportsList( newSportsList )
            setVisibleModal( null )
        }

        return ( {
            createSports:
                <SportsCreateTemplate
                    onSucceeded={ updateSportsList }
                    onCancel={ () => setVisibleModal( null ) } />,
            changePassword:
                <PasswordChangeTemplate
                    url="/api/community/password/change/"
                    onSucceeded={ () => setVisibleModal( null ) }
                    onCancel={ () => setVisibleModal( null ) } />,
            resetPassword:
                <PasswordResetTemplate
                    url="/api/community/password/reset/"
                    initialEmail={ initialEmail }
                    onSucceeded={ () => setVisibleModal( null ) }
                    onCancel={ () => setVisibleModal( null ) } />
        }[ visibleModal as VisibleModalType ] )
    }


    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="TeamInfo" />
                <div className={ Styles.container }>
                    <div className={ Styles.image_container }>
                        <img className={ Styles.team_image }
                            src={ typeof imagePath === "string" ?
                                imagePath :
                                imagePath && isFile( imagePath ) ? window.URL.createObjectURL( imagePath ) : "" } />
                        <input type="file" ref={ imageInputRef } onChange={ () => onChangeInputImage() } />
                        <div className={ Styles.image_add_icon } onClick={ () => imageInputRef.current?.click() }>
                            <PlusIcon />
                        </div>
                    </div>
                    <ul className={ Styles.info_list }>
                        { infoList.map( info =>
                            <InputInfoItem
                                label={ info.label }
                                name={ info.name }
                                value={ info.value }
                                multipleLines={ info.name === "status_message" }
                                key={ info.name }
                                errorMessage={ "" }
                                onChange={ onChangeInput } />
                        ) }
                    </ul>
                    <div className={ Styles.info_list }>
                        <div className={ Styles.toggle_info_item }>
                            <label className={ Styles.info_item_label }>{ "チーム情報の非公開" }</label>
                            <div style={ { display: "flex", width: "100%" } }>
                                <ToggleButton value={ !openProfile } onChange={ ( value ) => setOpenProfile( value ) } />
                            </div>
                        </div>
                    </div>
                    <SelectBoxWithInputSearch
                        buttonList={ sportsList }
                        onChangeSelectButton={ onChangeSelectButton }
                        type="radio"
                        label="スポーツ"
                        boxName="sports"
                        placeholder="スポーツを検索"
                        style={ { justifyContent: "flex-end", padding: "12px 20px" } } />
                    <p className={ Styles.modal_visible_text } onClick={ () => setVisibleModal( VisibleModal.CREATE_SPORTS ) }>お探しのスポーツがなかった場合はこちら</p>
                    <p className={ Styles.modal_visible_text } onClick={ () => setVisibleModal( VisibleModal.CHANGE_PASSWORD ) }>パスワードを変更</p>
                    <p className={ Styles.modal_visible_text } onClick={ () => setVisibleModal( VisibleModal.RESET_PASSWORD ) }>パスワードをお忘れの方はこちら</p>
                    <p style={ { textAlign: "center", width: "100%", marginTop: 16, marginBottom: 16 } }>
                        <GeneralButton onClick={ () => saveInfo() } style={ { width: "100%", margin: "auto", } } title="保存" loading={ uploading } />
                    </p>
                    { renderModal() }
                </div>
            </>
        </Layout>
    )
}

export default TeamInfoUpdatePage
